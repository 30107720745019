import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import axios from "axios"

import { PLATFORM } from "../../enum"

export const BOOKING_SUBMIT = gql`
  mutation bookingSubmit($Input: CreateBookingInput!) {
    bookingSubmit(Input: $Input) {
      reference_no
      reservation_id
      status
    }
  }
`

const MobileBooking = () => {
  const [hiddenForm, setHiddenForm] = useState<any>(<></>)
  const [mobileDetails, setMobileDetails] = useState<any | null>(null)
  const [bookingSubmit] = useMutation(BOOKING_SUBMIT)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const detailsQuery = urlParams.get("details")

    if (detailsQuery) {
      setMobileDetails(JSON.parse(detailsQuery))
    }
  }, [])

  useEffect(() => {
    if (mobileDetails) {
      payMobile(mobileDetails)
    }
  }, [mobileDetails])

  const payMobile = async values => {
    if (typeof window !== "undefined") {
      window.onbeforeunload = null
    }

    const newValues = {
      UserName: `${values.firstName} ${values.lastName}`,
      UserEmail: values.emailAddress,
      UserContact: values.mobileNo,
      ProdDesc: `Hotel Stay at ${values.hotelName}`,
      special_instruction: values.specialInstruction,
      Amount: values.paymentTotalAmount,
      hotel_id: values.hotelId,
      platform: "mobile_app",
      use_saved_card: false,
      wallet_id: "",
      checkInDate: values.checkInDate,
      checkOutData: values.checkOutDate,
      partner_data_id: values.partnerData,
    }

    const inputData = {
      hotelId: newValues.hotel_id,
      partner_data: newValues.partner_data_id,
      checkInDate: newValues.checkInDate,
      checkOutDate: newValues.checkOutData,
      party: values.party,
      platform: PLATFORM.MOBILE,
      first_name: values.firstName,
      last_name: values.lastName,
      guest_tracker_id: values.guestTrackerId,
      country_code: values.countryCode,
      mobile_no: newValues.UserContact,
      email_address: newValues.UserEmail,
      special_instruction: newValues.special_instruction,
      customer_list: values.customerList,
      promo_details: values.promoDetails,
      add_ons: values.addOns,
      card_number: values.cardNumber,
      final_price_at_booking: { amount: parseFloat(values.totalAmount) },
      final_price_at_checkout: { amount: 0 },
    }

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("act")}` || null,
      },
    }

    const checkoutData = await axios
      .post(
        `${process.env.GATSBY_REST_API_URL}/payment/checkout`,
        newValues,
        config
      )
      .then(res => res.data)

    inputData["reference_no"] = checkoutData.RefNo

    const { data } = await bookingSubmit({
      variables: {
        Input: await inputData,
      },
    })

    if (typeof data !== "undefined" && data !== null) {
      const { status } = await data.bookingSubmit
      if (status === "BOOKED") {
        const redirect = `${process.env.GATSBY_SITE_URL}booking-confirmation/?status=booked&reservation_id=${data.bookingSubmit.reference_no}&amount=${newValues.Amount}&name=${newValues.UserName}`
        window.location.replace(redirect)
      } else if (status === "PENDING") {
        const paymentForm = (
          <form
            action="https://payment.ipay88.com.ph/epayment/entry.asp"
            method="post"
            name="ePayment"
            id="form"
          >
            <input
              type="hidden"
              name="MerchantCode"
              value={checkoutData.MerchantCode}
            />
            <input
              type="hidden"
              name="PaymentId"
              value={checkoutData.PaymentId}
            />
            <input type="hidden" name="RefNo" value={checkoutData.RefNo} />
            <input type="hidden" name="Amount" value={newValues.Amount} />
            <input type="hidden" name="Currency" value="PHP" />
            <input type="hidden" name="ProdDesc" value={newValues.ProdDesc} />
            <input type="hidden" name="UserName" value={newValues.UserName} />
            <input type="hidden" name="UserEmail" value={newValues.UserEmail} />
            <input
              type="hidden"
              name="UserContact"
              value={newValues.UserContact}
            />
            <input
              type="hidden"
              name="SignatureType"
              value={checkoutData.SignatureType}
            />
            <input
              type="hidden"
              name="Signature"
              value={checkoutData.Signature}
            />
            <input
              type="hidden"
              name="ResponseURL"
              value={`${process.env.GATSBY_REST_API_URL}/payment/response`}
            />
            <input
              type="hidden"
              name="BackendURL"
              value={`${process.env.GATSBY_REST_API_URL}/payment/backend`}
            />
            <input
              type="submit"
              value="Submit"
              id="submitButton"
              style={{ display: "none" }}
            />
          </form>
        )

        setHiddenForm(paymentForm)
      }
    }
  }

  useEffect(() => {
    document.getElementById("submitButton")?.click()
  }, [hiddenForm])

  return <div>{hiddenForm}</div>
}

export default MobileBooking
